.about-us {
  font-weight: bold;
  font-size: 38px;
  color: #414040;
  font-family: "Metropolis";
}
.about-description {
  font-size: 14px;
  color: #535353;
  font-family: "Metropolis";
}
