.items-style {
  /* height: 270px; */
  /* width: 100%; */
  /* background-color: #683bb7; */
  color: #fff;
  margin: 15px;
  font-size: 4em;
}
.items-name {
  font-size: 12px;
  color: #000;
  font-weight: bold;
}
.items-year {
  font-size: 12px;
  color: #3b3b3b;
  font-weight: bold;
}

@media only screen and (max-width: 375px) {
  .items-image {
    margin: 0 !important;
  }
  .items-content {
    margin: 0 !important;
  }
}
