#career {
    background-image: url('../../assets/images/background_line.svg');
    background-repeat: no-repeat;
    background-size: cover;
}
#career .subheader {
   color: #936957;
   font-size: 25px;
   font-family: "Metropolis";
   margin-bottom: 25px;
   font-weight: 600;
}
#career .paragraph {
   /* font-size: 21px; */
   color: black;
   padding-block-start: 10px;
   font-family: "Metropolis";
}
#career .mail{
   text-decoration: underline;
   color: #936957;
   /* font-size: 25px; */
   font-family: "Metropolis";
}