.practices-slide {
  /* height: 270px; */
  width: 100%;
  /* background-color: #683bb7; */
  color: #fff;
  margin-left: 15px;
  font-size: 4em;
}
.practices-img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}
.imag-detailes {
  position: relative;
  bottom: 67.9px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  font-family: "Metropolis";
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  /* padding: 22.5px; */
  padding-bottom: 20px;
  padding-top: 28px;
}
@media only screen and (max-width: 425px) {
  .practices-slide {
    /* height: 270px; */
    width: 100%;
    /* background-color: #683bb7; */
    color: #fff;
    margin-left: 0px;
    font-size: 4em;
  }
}
