.navBackground {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 9999999999999999;
}
.nav-title {
  font-size: 19px;
  color: #0e0e0e;
  font-family: "Metropolis";
  text-align: center;
}
.nav-title-drop {
  font-size: 19px;
  color: #0e0e0e;
  font-family: "Metropolis";
  text-align: center;
}
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
.nav-sub-title {
  font-size: 12px;
  color: #0e0e0e;
  font-family: "Metropolis";
}

.newsLink{
  /* margin: 0px -30px 0px -15px ; */
  margin: 0 -38px 0 -25px;
}

@media only screen and (max-width: 767px) {

.newsLink{
  margin: 0;
}
}

@media only screen and (min-width: 991px) {
.navbar-expand-lg .navbar-nav .nav-link{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
}

@media only screen and (max-width: 991px) {
  .nav-sub-title {
    font-size: 12px;
    color: #0e0e0e;
    font-family: "Metropolis";
    text-align: center !important;
  }
}
@media only screen and (max-width: 425px) {
  .nav-title {
    font-size: 16px;
  }
}
